import { instance as axios } from './axios_common';

export const login = async (username, password) => {
	return axios.post('users/login', {
		username: username.value,
		password: password.value,
	});
};

export const logout = async (userToken) => {
	return axios.post(
		'users/logout',
		{},
		{
			headers: {
				Authorization: `Bearer ${userToken}`,
			},
		}
	);
};

export const signUp = async (username, password) => {
	return axios.post('users/signup', {
		username: username.value,
		password: password,
	});
};
