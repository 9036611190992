import { instance as axios } from './axios_common';

export const getQuestion = (category_id, fixed = false) => {
	return axios.post(
		'quiz/randomQuestion',
		{ category_id, fixed },
		{
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		}
	);
};

export const answerQuestion = (answerId, time) => {
	return axios.post(
		'quiz/userResponse',
		{ answerId, time },
		{
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		}
	);
};

export const missingAnswer = (questionId) => {
	return axios.post(
		'quiz/missingResponse',
		{ questionId },
		{
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		}
	);
};

export const quizScore = (questionNumber) => {
	return axios.post(
		'quiz/quizScore',
		{ questionNumber },
		{
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		}
	);
};

export const quizResponses = (questionNumber) => {
	return axios.post(
		'quiz/quizResponses',
		{ questionNumber },
		{
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		}
	);
};

export const answerBonusQuestion = (answerId, score) => {
	return axios.post(
		'quiz/userBonusResponse',
		{ answerId, score },
		{
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		}
	);
};

export const getFullRanking = (category) => {
	return axios.post(
		'/stats/fullRanking',
		{ category },
		{
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		}
	);
};

export const resetCategoryResponses = (category) => {
	return axios.post(
		'quiz/resetCategoryResponses',
		{ category },
		{
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		}
	);
};
