const checkTokenError = (err) => {
	if (err.code === 'ERR_NETWORK') {
		alert('The server is not responding!');
		return null;
	}
	if (
		err?.response?.data?.message === 'TOKEN_NOT_FOUND' ||
		err?.response?.data?.message === 'TOKEN_EXPIRED' ||
		err?.response?.data?.message === 'UNAUTHORIZED_USER_ERROR' ||
		err?.response?.data?.message === 'TOKEN_MALFORMED'
	) {

		localStorage.removeItem('token');
		window.location.replace(`/${window.location.search}`)
		return;



	}
};

export default checkTokenError;
