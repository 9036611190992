/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SpinnerDotted } from 'spinners-react';
import './Ranking.scss';
import { getFullRanking } from './services';
import { getProfile } from './services';
import checkTokenError from './utils/checkTokenError';
import getCategory from './utils/getCategory';

const Ranking = () => {
	const [ranking, setRanking] = useState(null);
	const [user, setUser] = useState(null);

	const rankLimit = 5;

	useEffect(async () => {
		try {
			const u = await getProfile();
			setUser(u?.data?.data);
			const result = await getFullRanking(localStorage.getItem('category'));
			setRanking(result?.data?.data);
		} catch (err) {
			console.log('ERROR_GET_RANKING:', err);
			checkTokenError(err);
		}
	}, []);

	let content = null;

	if (ranking && ranking.length !== 0) {
		content = ranking?.map((rank, i) => {
			if (i < rankLimit) {
				return (
					<tr key={i} className={user.id === rank.id ? getCategory() : ''}>
						<td className="pos">{i + 1}</td>
						<td className="username">{rank.username}</td>
						<td className="score">{rank.score}</td>
					</tr>
				);
			} else if (user.id === rank.id) {
				return (
					<div className="self-ranking">
						<tr key={i} className={user.id === rank.id ? getCategory() : ''}>
							<td className="pos">{i + 1}</td>
							<td className="username">{rank.username}</td>
							<td className="score">{rank.score}</td>
						</tr>
					</div>
				);
			}
			return '';
		});
	}

	return (
		<div className={`page ${getCategory()}`}>
			<div className={`container ${window.self === window.top ? 'with-logo' : ''} classifica`}>
				<h1>Classifica TOP 5</h1>
				{content ? (
					<div className="ranking">
						<table>
							<thead>
								<tr>
									<th>#</th>
									<th>Username</th>
									<th>Punteggio</th>
								</tr>
							</thead>
							<tbody>{content}</tbody>
						</table>
					</div>
				) : (
					<SpinnerDotted size={50} />
				)}
				<Link to="/">
					<button>Home</button>
				</Link>
			</div>
		</div>
	);
};

export default Ranking;
