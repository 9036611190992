/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { getProfile } from './services';
import checkTokenError from './utils/checkTokenError';

const UserInfo = () => {
	const [user, setUser] = useState(null);

	useEffect(async () => {
		try {
			const result = await getProfile(localStorage.getItem('category'));
			setUser(result?.data?.data);
		} catch (err) {
			console.log('ERROR_GET_USER_INFO:', err?.response?.data);
			checkTokenError(err);
		}
	}, []);
	return (
		<div className="userInfo">
			<p>
				Ciao, <b>{user?.username || `...`}!</b>
			</p>
			<p>
				Il tuo punteggio è <b>{user?.score || `...`}</b>
			</p>
		</div>
	);
};

export default UserInfo;
