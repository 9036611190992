import { instance as axios } from './axios_common';

export const getProfile = async (category) => {
	return axios.post(
		'users/profile',
		{ category },
		{
			headers: {
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			},
		}
	);
};
