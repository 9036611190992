/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import './Question.scss';

import {
	answerBonusQuestion,
	answerQuestion,
	getCategories,
	getQuestion,
	missingAnswer,
	quizScore,
	quizResponses,
	resetCategoryResponses,
} from './services';
import checkTokenError from './utils/checkTokenError';
import { Link } from 'react-router-dom';
import getCategory from './utils/getCategory';
import rightSound from './assets/sound/s_esatto.mp3';
import wrongSound from './assets/sound/s_sbagliato.mp3';
import getLogo from './utils/getLogo';

import Icon from '@mdi/react';
import { mdiVolumeHigh, mdiVolumeOff } from '@mdi/js';

const Question = (props) => {
	const quizLimit = 6;
	const timeLimit = 20;

	const [questionId, setQuestionId] = useState(props.questionId);
	const [question, setQuestion] = useState(props.question);
	const [score, setScore] = useState(0);
	const [finalScore, setFinalScore] = useState(0);
	const [esitoBonus, setEsitoBonus] = useState(null);
	const [activeButton, setActiveButton] = useState(null);
	const [colorButton, setColorButton] = useState(null);
	const [time, setTime] = useState(null);
	const [timestamp, setTimestamp] = useState(null);
	const [colorTimeout, setColorTimeout] = useState(false);
	const [categories, setCategories] = useState(null);
	const [category, setCategory] = useState(null);
	const [questionDots, setQuestionDots] = useState(Array(quizLimit).fill(null));

	const [reviewQuestions, setReviewQuestions] = useState([]);

	const [countdown, setCountdown] = useState(false);

	const [volume, setVolume] = useState(localStorage.getItem('volume') === 'true' ? true : false);

	const rightAudio = new Audio(rightSound);
	rightAudio.volume = 0.6;
	const wrongAudio = new Audio(wrongSound);
	wrongAudio.volume = 0.6;

	let debuggerDiv = '';

	useEffect(() => {
		if (questionId === -1 && !localStorage.getItem('category')) {
			async function loadCategories() {
				try {
					const data = await getCategories();
					setCategories(data?.data?.data);
				} catch (err) {
					checkTokenError(err);
					console.log('ERROR_MISSING_CATEGORIES', err);
				}
			}
			loadCategories();
		}
		if (questionId > -1 && questionId !== quizLimit && questionId !== quizLimit + 2 && !countdown) {
			const x = setTimeout(() => {
				setColorTimeout(true);
				setTimeout(async () => {
					setColorTimeout(false);
					try {
						await missingAnswer(question?.question?.id);
						const temp = [...questionDots];
						temp[questionId] = false;
						setQuestionDots(temp);
						const res = await getQuestion(
							category ? category : localStorage.getItem('category'),
							category ? true : false
						);
						setTimestamp(new Date());
						setQuestion(res?.data?.data);
						setQuestionId(questionId + 1);
					} catch (err) {
						if (err.code === 'ERR_NETWORK') {
							alert('The server is not responding!');
						}
						console.log('ERROR_MISSING_ANSWER', err);
					}
				}, 2000);
			}, timeLimit * 1000);
			setTime(x);
			return () => clearTimeout(x);
		}
	}, [questionId, countdown]);

	const start = async (category_id) => {
		try {
			setCountdown(true);
			setCategory(category_id);
			const res = await getQuestion(category_id, localStorage.getItem('category') ? true : false);
			setTimestamp(new Date());
			setQuestion(res?.data?.data);
			setQuestionId(0);
			setTime(new Date());
			setScore(0);
			setQuestionDots(Array(quizLimit).fill(null));
			setFinalScore(0);
		} catch (err) {
			console.log('ERROR_GET_START_QUESTION:', err);
			checkTokenError(err);
			if (err?.response?.data?.message === 'CATEGORY_QUIZ_END') {
				setQuestionId(-3);
			}
			if (err?.response?.data?.message === 'QUIZ_END') {
				setQuestionId(-4);
			}
		}
	};

	const handler = async (index) => {
		setActiveButton(index);
		clearTimeout(time);
		try {
			let ans = null;
			if (score !== 0 && questionId === quizLimit + 1) {
				let prevScore = score * 1;
				ans = await answerBonusQuestion(index, score);
				setScore(0);
				const res = await quizScore(quizLimit + 1);
				setFinalScore(res?.data?.data?.score);
				if (res?.data?.data?.score >= prevScore) {
					setEsitoBonus('Complimenti, hai raddoppiato il tuo punteggio!');
				} else {
					setEsitoBonus('Peccato, hai dimezzato il tuo punteggio.');
				}
			} else {
				ans = await answerQuestion(index, Math.abs(new Date() - timestamp) / 1000);
			}
			if (questionId + 1 === quizLimit) {
				const res = await quizScore(quizLimit);
				setScore(res?.data?.data?.score);
			}
			if (ans?.data?.score > 0) {
				// Right answer
				if (volume) {
					rightAudio.play();
				}
				setColorButton('#AFE1AF');
				const temp = questionDots;
				temp[questionId] = true;
				setQuestionDots(temp);
			} else {
				// Wrong answer
				if (volume) {
					wrongAudio.play();
				}
				setColorButton('#F88379');
				const temp = [...questionDots];
				temp[questionId] = false;
				setQuestionDots(temp);
			}

			const q =
				questionId < quizLimit
					? await getQuestion(category ? category : localStorage.getItem('category'), category ? true : false)
					: null;
			setTimeout(() => {
				setActiveButton(null);
				setColorButton(null);
				if (questionId < quizLimit) {
					setTimestamp(new Date());
					setQuestion(q.data.data);
				}
				setQuestionId(questionId + 1);
			}, 200);
		} catch (err) {
			console.log('HANDLER QUESTION:', err);
			checkTokenError(err);
			if (err?.response?.data?.message === 'CATEGORY_QUIZ_END') {
				setQuestionId(-3);
			}
			if (err?.response?.data?.message === 'QUIZ_END') {
				setQuestionId(-4);
			}
		}
	};

	const bonusQuestion = async () => {
		try {
			setQuestionDots(Array(1).fill(null));
			const q = await getQuestion(category ? category : localStorage.getItem('category'), category ? true : false);
			setQuestion(q?.data?.data);
			setQuestionId(questionId + 1);
		} catch (err) {
			console.log('ERROR_GET_START_QUESTION:', err);
			checkTokenError(err);
		}
	};

	const startAgain = () => {
		setQuestion('');
		setQuestionId(-1);
		setScore(0);
		setQuestionDots(Array(quizLimit).fill(null));
		setFinalScore(0);
	};

	const renderTime = ({ remainingTime }) => {
		return (
			<div className="timer">
				<div className="value">{Math.round((150 * question.question.level * Math.round(remainingTime)) / 20)}</div>
			</div>
		);
	};

	const renderTimeCountDown = ({ remainingTime }) => {
		return (
			<div className="timer">
				<div className="value">{remainingTime === 0 ? 'Via!' : remainingTime}</div>
			</div>
		);
	};

	const reviewAnswers = async () => {
		try {
			const res = await quizResponses(questionId);
			setReviewQuestions(res?.data?.data);
			setQuestionId(-2);
		} catch (err) {
			console.log('REVIEW ERROR:', err);
			checkTokenError(err);
		}
	};

	const toHome = () => {
		window.location.replace('/');
	};

	let content = null;
	const withIframe = (
		<div className="container-btn start-menu">
			<h1 className={localStorage.getItem('category') ? getCategory() : ''}>Sei pronto a giocare?</h1>
			<button
				className="btn-start"
				onClick={() => start(localStorage.getItem('category') ? localStorage.getItem('category') : 1)}
			>
				Avvia
			</button>
		</div>
	);

	const noIframe = categories?.map((category) => {
		return (
			<button key={category.id} className={category.stile} onClick={() => start(category.id)}>
				{category.title}
			</button>
		);
	});

	const volumeChange = () => {
		localStorage.setItem('volume', !volume);
		setVolume(!volume);
	};

	const backTo = () => {
		if (questionId === -2) {
			setQuestionId(reviewQuestions.length);
		}
	};

	const resetQuiz = async () => {
		try {
			await resetCategoryResponses(category);
			setActiveButton(null);
			setColorButton(null);
			startAgain();
		} catch (err) {
			console.log('RESET QUIZ ERROR:', err);
			checkTokenError(err);
		}
	};

	if (questionId === -1) {
		content = (
			<>
				<div className="banner">
					<img src={getLogo()} className="logo" alt="Green Quiz" />
				</div>
				{localStorage.getItem('category') ? withIframe : <div className="btn-categories">{noIframe}</div>}
				<div className="container-btn">
					<Link to="/">Annulla</Link>
				</div>
			</>
		);
	} else if (questionId === -2) {
		content = (
			<div className="review">
				<table>
					<thead>
						<h2>Review</h2>
					</thead>
					<tbody className="scrollbar">
						{reviewQuestions
							.map((reviewQ, i) => (
								<tr key={i}>
									<th>
										<div className={`circle ${reviewQ.score > 0 ? 'green' : 'red'}`}></div>
									</th>
									<th className="left">{reviewQ.question}</th>
								</tr>
							))
							.reverse()}
					</tbody>
				</table>
				<div className="container-btn">
					<button className="btn-finish" onClick={backTo}>
						Indietro
					</button>
				</div>
			</div>
		);
	} else if (questionId === -3) {
		content = (
			<div className="quiz-end">
				<h2>
					Ottimo lavoro! Hai completato le domande di questa categoria, rispondendo a tutti i quiz in modo corretto.
				</h2>
				<p>
					Applica le tue nuove conoscenze e dai il tuo personale contributo per la salvaguardia del Pianeta. Se vuoi
					ricominciare a giocare, puoi farlo attraverso il tasto “Reset” che azzera la tua situazione di gioco. Oppure
					prova le altre categorie.
				</p>
				<div className="container-btn">
					<button className="btn-finish" onClick={resetQuiz}>
						Reset Categoria
					</button>
					<button className="btn-finish" onClick={startAgain}>
						Indietro
					</button>
				</div>
			</div>
		);
	} else if (questionId === -4) {
		content = (
			<div className="quiz-end">
				<h2>Ottimo lavoro! Hai completato il gioco, rispondendo a tutti i quiz in modo corretto.</h2>
				<p>
					Applica le tue nuove conoscenze e dai il tuo personale contributo per la salvaguardia del Pianeta. Se vuoi
					ricominciare a giocare, puoi farlo attraverso il tasto “Reset” che azzera la tua situazione di gioco.
				</p>
				<div className="container-btn">
					<button className="btn-finish" onClick={resetQuiz}>
						Reset Categoria
					</button>
					<button className="btn-finish" onClick={toHome}>
						Home
					</button>
				</div>
			</div>
		);
	} else if (questionId >= quizLimit && questionId !== quizLimit + 1) {
		let inside =
			score !== 0 ? (
				<>
					<h2>Vuoi raddoppiare il tuo punteggio?</h2>
					<h3>Punteggio partita attuale: {score} punti</h3>
					<div className="btn-bonus">
						<button onClick={toHome}>No</button>
						<button onClick={bonusQuestion}>Sì</button>
					</div>
					<button className="btn-finish" onClick={reviewAnswers}>
						Revisione risposte
					</button>
					<button className="btn-finish" onClick={startAgain}>
						Nuova partita
					</button>
				</>
			) : (
				<>
					<h2>Quiz Terminato</h2>
					<h3>
						{esitoBonus}
						<br></br>Punteggio finale: {finalScore}
					</h3>
					<button className="btn-finish" onClick={reviewAnswers}>
						Revisione risposte
					</button>
					<button className="btn-finish" onClick={startAgain}>
						Nuova partita
					</button>
					<Link to="/">
						<button className="btn-finish">Home</button>
					</Link>
				</>
			);
		content = <div className={score !== 0 ? 'bonus' : 'classifica'}>{inside}</div>;
	} else if (questionId === 0 && countdown) {
		content = (
			<div className="start-countdown">
				<CountdownCircleTimer
					key={countdown}
					isPlaying
					duration={5}
					colors={['#00a077', '#00a077', '#00a077', '#00a077', '#00a077']}
					colorsTime={[5, 4, 3, 2, 1]}
					size={200}
					onComplete={() =>
						setTimeout(() => {
							setCountdown(false);
						}, 1000)
					}
				>
					{renderTimeCountDown}
				</CountdownCircleTimer>
				<div className="container-btn">
					<Link
						to=""
						style={{ marginTop: '10px' }}
						onClick={() => {
							setQuestionId(-1);
							setCountdown(false);
						}}
					>
						Annulla
					</Link>
				</div>
			</div>
		);
	} else {
		const circles = [];
		for (let i = 0; i < (questionId === quizLimit + 1 ? 1 : questionDots.length); i++) {
			const color = typeof questionDots[i] === 'object' ? '' : questionDots[i] ? 'green' : 'red';
			const focus = i === questionId || questionId === quizLimit + 1 ? 'focus' : '';
			circles.push(<div className={`circle ${color} ${focus}`} key={i}></div>);
		}

		if (sessionStorage.getItem('gq_debugger') !== null) {
			debuggerDiv = (
				<div
					style={{
						padding: '4px',
						height: '20px',
						lineHeight: '20px',
						background: 'white',
						position: 'fixed',
						top: '10px',
						left: '10px',
					}}
				>
					{question.question.id}
				</div>
			);
		}

		content = (
			<>
				{debuggerDiv}
				<div className="question">
					<span>{question.question.question}</span>
					<div className="countdown">
						<CountdownCircleTimer
							key={questionId}
							isPlaying
							duration={20}
							colors={['#004777', '#63C988', '#F7B801', '#A30000']}
							colorsTime={[20, 15, 5, 0]}
							size={50}
							strokeWidth={5}
						>
							{renderTime}
						</CountdownCircleTimer>
					</div>
				</div>
				<div className="answers">
					{question?.options?.map((answer) => {
						return (
							<div
								key={answer?.id}
								className={`answer ${activeButton === null ? 'answer-action' : ''} ${
									colorTimeout ? 'error-color' : ''
								}`}
								onClick={activeButton === null ? (event) => handler(answer?.id) : null}
								style={{
									backgroundColor: activeButton === answer?.id ? colorButton : 'white',
								}}
							>
								{answer?.q_option}
							</div>
						);
					})}
				</div>
				<div className="circles-outer">
					<div className="circles">{circles}</div>
				</div>
			</>
		);
	}
	return (
		<div className={`page ${getCategory()}`}>
			<div
				className={`container ${questionId === -2 ? 'big' : ''} ${
					questionId !== -1 ? `with-logo ${getCategory()}` : ''
				} `}
			>
				{content}
				<button className="volume" onClick={volumeChange}>
					<Icon path={volume ? mdiVolumeHigh : mdiVolumeOff} title="Volume On" size={1.5} color="rgba(0,0,0,.35)" />
				</button>
			</div>
		</div>
	);
};

export default Question;
