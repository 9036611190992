import React from 'react';
import { Link } from 'react-router-dom';
import getCategory from './utils/getCategory';
import './Instruction.scss';

const Instruction = () => {
	// useEffect(async () => {}, []);

	let content = (
		<div>
			<ol>
				<li>
					Ogni partita è composta da 6 domande. Il sistema ti proporrà solo i quiz a cui non hai ancora risposto
					correttamente, fino all’esaurimento di tutti i quiz disponibili.
				</li>

				<li>
					{' '}
					Ogni domanda genera un punteggio in funzione del livello di difficoltà (da 150 a 450 punti) e del tuo tempo di
					risposta (massimo 20 secondi). Se non rispondi o sbagli otterrai un punteggio pari a zero.
				</li>

				<li>
					A fine partita puoi provare a raddoppiare il punteggio con una domanda aggiuntiva… ma attenzione: se sbagli il
					tuo punteggio sarà dimezzato!
				</li>
				<li>
					{' '}
					Il tuo punteggio complessivo si aggiorna di partita in partita: dai un’occhiata alla classifica per vedere la
					tua posizione.
				</li>
			</ol>
		</div>
	);

	return (
		<div className={`page ${getCategory()}`}>
			<div className={`container big ${window.self === window.top ? 'with-logo' : ''} istruzioni`}>
				<h1>Come funziona il gioco: </h1>
				{content ? <>{content}</> : 'NO DATA'}
				<h3 style={{ lineHeight: 0.5, textAlign: 'center' }}>Non ti resta che giocare!</h3>
				<div className="btn-container-wrap">
					<Link to="/quiz">
						<button>Gioca</button>
					</Link>
					<Link to="/">
						<button>Home</button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Instruction;
