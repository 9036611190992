/* eslint-disable jsx-a11y/anchor-is-valid */
import './assets/easyreading/easyreading.css';
import './App.scss';
import { useState } from 'react';
import UserInfo from './UserInfo';
import { Link, useSearchParams } from 'react-router-dom';
import { login, logout, signUp } from './services';
import checkTokenError from './utils/checkTokenError';
import getCategory from './utils/getCategory';
import { Alert } from '@mui/material';
import { SpinnerCircular } from 'spinners-react';
import validator from 'validator';

import Icon from '@mdi/react';
import { mdiVolumeHigh, mdiVolumeOff } from '@mdi/js';
import getLogo from './utils/getLogo';

function App() {
	if (localStorage.getItem('volume') === null) {
		localStorage.setItem('volume', true);
	}

	const [form, setForm] = useState('login');
	const [error, setError] = useState(null);

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');

	const [showIntro, setShowIntro] = useState(localStorage.getItem('is_new') ? localStorage.getItem('is_new') : false);
	const [loaderVisible, setLoaderVisible] = useState(false);
	const [volume, setVolume] = useState(localStorage.getItem('volume') === 'true' ? true : false);

	const params = useSearchParams();
	if (localStorage.getItem('category') !== params[0].get('category') && params[0].get('category') !== null) {
		if (['1', '2', '3', '4', '5', '6'].includes(params[0].get('category'))) {
			localStorage.setItem('category', params[0].get('category'));
			//window.location.replace(`/${window.location.search}`);
		} else {
			localStorage.removeItem('category');
			//window.location.replace(`/${window.location.search}`);
		}
	} else if (params[0].get('category')) {
		//window.location.replace(`/`);
	}

	if (params[0].get('logout_redirect') !== null) {
		sessionStorage.setItem('logout_redirect', decodeURI(params[0].get('logout_redirect')));
	}

	if (params[0].get('debugger') !== null) {
		sessionStorage.setItem('gq_debugger', '1');
	}

	const validationOption = {
		minLength: 8,
		minUppercase: 1,
		minLowercase: 1,
		minNumbers: 1,
		minSymbols: 0,
	};

	const [user, setUser] = useState(localStorage.getItem('token'));
	if (localStorage.getItem('token') !== params[0].get('token') && params[0].get('token') !== null) {
		localStorage.setItem('token', params[0].get('token'));
		if (params[0].get('is_new') && params[0].get('is_new') === '1') {
			localStorage.setItem('is_new', true);
		}
		//window.location.replace(`/${window.location.search}`);
	}
	const checkUserToken = () => {
		const token = localStorage.getItem('token');
		if (token !== user) setUser(token);
	};

	const handleLogin = async (event) => {
		event.preventDefault();
		const { username, password } = event.target;
		try {
			setLoaderVisible(true);
			const response = await login(username, password);

			setTimeout(() => {
				setLoaderVisible(false);
				if (response?.data?.is_new) {
					setShowIntro(true);
				}
				localStorage.setItem('token', response.data.token);
				checkUserToken();
				setError(null);
			}, 1000);
		} catch (err) {
			if (err.code === 'ERR_NETWORK') {
				alert('The server is not responding!');
			}
			console.log('LOGIN_ERROR:', err?.response?.data?.message);
			setTimeout(() => {
				setLoaderVisible(false);
				setError(err?.response?.data?.message);
			}, 1000);
		}
	};

	const handleLogout = async () => {


		if (window.self !== window.top) {
			window.top.location.href = sessionStorage.getItem("logout_redirect") || `https://www.scuolapark.it/`;
			return;
		}

		try {
			const response = await logout(user);
			if (response.status === 200) {
				localStorage.removeItem('token');
				localStorage.removeItem('category');
				checkUserToken();
			}
		} catch (err) {
			checkTokenError(err);
			localStorage.removeItem('category');
			console.log('LOGOUT_ERROR:', err?.response?.data?.message);
		}
	};

	const handleSignUp = async (event) => {
		event.preventDefault();
		const { username } = event.target;
		try {
			if (password === passwordConfirm) {
				setLoaderVisible(true);
				const response = await signUp(username, password);

				setTimeout(() => {
					setLoaderVisible(false);
					if (response.status === 200) {
						setForm('login');
						setError(false);
					}
				}, 1000);
			} else {
				setError('The 2 password does not matches');
			}
		} catch (err) {
			if (err.code === 'ERR_NETWORK') {
				alert('The server is not responding!');
			}
			console.log('REGISTRATION_ERROR:', err?.response?.data?.message);

			setTimeout(() => {
				setLoaderVisible(false);
				setError(err?.response?.data?.message);
			}, 1000);
		}
	};

	const updateInput = (event) => {
		const { name, value } = event?.target;
		switch (name) {
			case 'password':
				setPassword(value);
				if (!validator.isStrongPassword(value, validationOption) && form === 'sign') {
					setError(
						<>
							Requisiti della password:
							<ul>
								<li>Almeno 8 caratteri</li>
								<li>Almeno una lettera maiuscola</li>
								<li>Almeno un numero</li>
							</ul>
						</>
					);
				} else if (passwordConfirm !== '') {
					setError(value !== passwordConfirm ? 'Le password non coincidono' : null);
				} else {
					setError(null);
				}
				break;
			case 'passwordConfirm':
				setPasswordConfirm(value);
				if (validator.isStrongPassword(password, validationOption)) {
					setError(value !== password ? 'Le password non coincidono' : null);
				}
				break;
			case 'username':
				setUsername(value);
				break;
			default:
				break;
		}
	};

	const changeForm = () => {
		if (!loaderVisible) {
			if (form === 'login') {
				setUsername('');
				setPassword('');
				setPasswordConfirm('');
				setForm('sign');
			} else {
				setPassword('');
				setPasswordConfirm('');
				setForm('login');
			}
			setError(null);
		}
	};

	const volumeChange = () => {
		localStorage.setItem('volume', !volume);
		setVolume(!volume);
	};

	const skipIntro = () => {
		setShowIntro(false);
		localStorage.removeItem('is_new');
	};

	let loginForm = (
		<>
			<div className="login" style={{ display: (window.self === window.top ? 'none' : 'block') }} >
				<p>Ricarica la pagina per avviare il gioco</p>
			</div>
			<div className="login" style={{ display: (window.self !== window.top ? 'none' : 'block') }} >
				<form onSubmit={handleLogin}>
					<label>Username</label>
					<input type="text" id="username" name="username" value={username} onChange={updateInput} required />
					<label>Password</label>
					<input type="password" id="password" name="password" value={password} onChange={updateInput} required />
					<button className={`submit ${getCategory()} loader`} type="submit">
						{loaderVisible ? (
							<>
								<SpinnerCircular
									size={20}
									thickness={180}
									speed={100}
									color="#36ad47"
									secondaryColor="rgba(255, 255, 255, 1)"
								/>{' '}
								&nbsp;
							</>
						) : (
							''
						)}
						LOGIN
					</button>
				</form>
				<a className="link-change" onClick={changeForm}>
					REGISTRATI
				</a>
			</div>
		</>
	);

	let signInForm = (
		<>
			<div className="login" style={{ display: (window.self === window.top ? 'none' : 'block') }} >
				<p>Ricarica la pagina per avviare il gioco</p>
			</div>
			<div className="sign-in" style={{ display: (window.self !== window.top ? 'none' : 'block') }} >
				<form onSubmit={handleSignUp}>
					<label>Username</label>
					<input type="text" id="username" name="username" value={username} onChange={updateInput} required />
					<label>Password</label>
					<input type="password" id="password" name="password" onChange={updateInput} value={password} required />
					<label>Ripeti Password</label>
					<input type="password" id="passwordConfirm" name="passwordConfirm" onChange={updateInput} required />
					<button className={`submit ${getCategory()} loader`} type="submit">
						{loaderVisible ? (
							<>
								<SpinnerCircular
									size={20}
									thickness={180}
									speed={100}
									color="#36ad47"
									secondaryColor="rgba(255, 255, 255, 1)"
								/>{' '}
								&nbsp;{' '}
							</>
						) : (
							''
						)}
						REGISTRATI
					</button>
				</form>
				<a className="link-change" onClick={changeForm}>
					LOGIN
				</a>
			</div>
		</>
	);

	let home = (
		<div className="home">
			<img src={getLogo()} className="home-logo" alt="Green Quiz" />
			{showIntro ? (
				<>
					<p className="welcome">
						Ciao, ti diamo il benvenuto in <b>GreenQuiz</b>: gioca per migliorare le tue conoscenze nel campo della
						sostenibilità ambientale e partire in missione per il pianeta!
					</p>
					<div className="container-btn">
						<button className="btn-finish" onClick={skipIntro}>
							Avanti
						</button>
					</div>
				</>
			) : (
				<>
					<UserInfo />
					<div className="home-btn-container">
						<Link to="/quiz">
							<button>Gioca</button>
						</Link>
						{/* TODO instructions */}
						<Link to="/istruzioni">
							<button>Istruzioni</button>
						</Link>
						<Link to="/top5">
							<button>Classifica</button>
						</Link>
						<a>
							<button onClick={handleLogout}>Esci</button>
						</a>
					</div>
				</>
			)}
		</div>
	);
	return (
		<div className={`page ${getCategory()}`}>
			<div className={`container home-page ${window.self === window.top && !user ? 'with-logo' : ''}`}>
				<button className="volume" onClick={volumeChange}>
					<Icon path={volume ? mdiVolumeHigh : mdiVolumeOff} title="Volume On" size={1.5} color="rgba(0,0,0,.35)" />
				</button>
				{error ? (
					<Alert
						severity="error"
						onClose={() => {
							setError(null);
						}}
						className="error-container"
					>
						{error}
					</Alert>
				) : (
					''
				)}
				{user ? home : form === 'login' ? loginForm : signInForm}
			</div>
		</div>
	);
}

export default App;
